.text-center {
    text-align: center;
}

.show {
    display: block !important;
}

.hide {
    display: none !important;
}

.opacity05 {
    opacity: 0.5;
}

.text-uppercase {
    text-transform: uppercase;
}

.copy-msg {
    position: 'fixed';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    font-size: 4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transform: scale(0.1);
    color: white;
    &h1 {
        font-weight: 400;
        text-shadow: 1px 2px black;
        background: rgba(255, 255, 255, 0.2);
        width: 100%;
        text-align: center;
        margin-bottom: 0;
        padding: 1rem;
    }
    &p {
        font-size: 2rem;
        font-weight: 100;
    }
}
.copy-msg-show {
    opacity: 1;
    transform: scale(1);
    z-index: 25;
    transition: all 0.4s ease-in-out;
    transition-delay: 0.3s;
}

.copy-text {
    color: white;
}

.grecaptcha-badge {
    visibility: hidden;
}
.flag-external-alias {
    top: 0 !important;
    width: 75%;
}
