@charset "UTF-8";

/* ••••••••••••••••••••••••••••••••
© 2008-now Harris Blondman
All rights reserved
www.harrisblondman.nl
•••••••••••••••••••••••••••••••• */

// ! Notes
// ================================

//NOTE: For Abdallah
// --------------------------------
// Dropdown menu styling (text center works only on Chrome)
// in JS Add admin key to clipboard when button clicked?
// Handheld app disallow rotation
// We can add a flag-transition-on, that would be remove during resizing, to avoid unnnede animation, ok for you?

// ! Preferences
// ================================

// General
// --------------------------------
$prefs-debug: false;

// Variables
// --------------------------------
$color-1: rgb(0, 0, 0);
$color-2: rgb(255, 255, 255);
$color-3: rgb(250, 250, 250);
$color-4: rgb(222, 222, 222);
$color-5: rgb(220, 220, 220);
$color-6: rgb(190, 190, 190);
$color-7: rgb(150, 150, 150);
$color-8: rgb(100, 100, 100);
$color-9: rgb(75, 75, 75);
$color-10: rgb(40, 40, 40);
$color-11: rgb(20, 20, 20);
$color-20: rgb(80, 80, 215);
$color-21: rgb(255, 40, 150);
$color-22: rgb(70, 220, 90);
$color-23: rgb(255, 255, 170);
$color-24: rgb(0, 0, 170);
$color-25: rgb(255, 0, 0);
$color-26: rgb(0, 227, 77);
$color-27: rgb(0, 147, 209);
$color-28: rgb(207, 0, 99);
$color-29: rgb(181, 31, 31);
$color-30: rgb(5, 103, 52);
$color-31: rgba(80, 80, 215, 0.8);
$color-32: rgba(0, 0, 170, 0.8);
$color-33: rgba(0, 147, 209, 0.8);
$color-34: rgba(207, 0, 99, 0.8);
$color-35: rgba(255, 255, 255, 0.8);

$theme-colors: (
    'SpeakUp': (
        'text': $color-1,
        'text-in-yes': $color-3,
        'text-in-accent': $color-3,
        'background': $color-20,
        'no': $color-21,
        'yes': $color-22,
        'c1': $color-3,
        'c2': $color-4,
        'c3': $color-7,
        'accent': $color-20,
        'pin': $color-23,
        'logo': $color-3,
        'navigation': $color-3,
        'navigation-comment': $color-4,
        'vote': $color-20,
        'gdpr': $color-31,
        'dark': (
            'text': $color-5,
            'text-in-yes': $color-10,
            'text-in-accent': $color-10,
            'background': $color-11,
            'no': $color-21,
            'yes': $color-22,
            'c1': $color-10,
            'c2': $color-9,
            'c3': $color-8,
            'accent': $color-3,
            'pin': $color-23,
            'logo': $color-20,
            'navigation': $color-8,
            'navigation-comment': $color-8,
            'vote': $color-20,
            'gdpr': $color-31,
        ),
    ),
    'UniNE': (
        'text': $color-1,
        'text-in-yes': $color-3,
        'text-in-accent': $color-3,
        'background': $color-24,
        'no': $color-25,
        'yes': $color-26,
        'c1': $color-3,
        'c2': $color-4,
        'c3': $color-7,
        'accent': $color-24,
        'pin': $color-23,
        'logo': $color-3,
        'navigation': $color-3,
        'navigation-comment': $color-4,
        'vote': $color-24,
        'gdpr': $color-32,
        'dark': (
            'text': $color-5,
            'text-in-yes': $color-10,
            'text-in-accent': $color-10,
            'background': $color-11,
            'no': $color-25,
            'yes': $color-26,
            'c1': $color-10,
            'c2': $color-9,
            'c3': $color-8,
            'accent': $color-3,
            'pin': $color-23,
            'logo': $color-24,
            'navigation': $color-8,
            'navigation-comment': $color-8,
            'vote': $color-24,
            'gdpr': $color-32,
        ),
    ),
    'Unil': (
        'text': $color-1,
        'text-in-yes': $color-3,
        'text-in-accent': $color-3,
        'background': $color-27,
        'no': $color-21,
        'yes': $color-22,
        'c1': $color-3,
        'c2': $color-4,
        'c3': $color-7,
        'accent': $color-27,
        'pin': $color-23,
        'logo': $color-3,
        'navigation': $color-3,
        'navigation-comment': $color-4,
        'vote': $color-27,
        'gdpr': $color-32,
        'dark': (
            'text': $color-5,
            'text-in-yes': $color-10,
            'text-in-accent': $color-10,
            'background': $color-11,
            'no': $color-21,
            'yes': $color-22,
            'c1': $color-10,
            'c2': $color-9,
            'c3': $color-8,
            'accent': $color-3,
            'pin': $color-23,
            'logo': $color-27,
            'navigation': $color-8,
            'navigation-comment': $color-8,
            'vote': $color-27,
            'gdpr': $color-33,
        ),
    ),
    'UniGE': (
        'text': $color-1,
        'text-in-yes': $color-3,
        'text-in-accent': $color-3,
        'background': $color-28,
        'no': $color-21,
        'yes': $color-22,
        'c1': $color-3,
        'c2': $color-4,
        'c3': $color-7,
        'accent': $color-28,
        'pin': $color-23,
        'logo': $color-3,
        'navigation': $color-3,
        'navigation-comment': $color-4,
        'vote': $color-28,
        'gdpr': $color-34,
        'dark': (
            'text': $color-5,
            'text-in-yes': $color-10,
            'text-in-accent': $color-10,
            'background': $color-11,
            'no': $color-21,
            'yes': $color-22,
            'c1': $color-10,
            'c2': $color-9,
            'c3': $color-8,
            'accent': $color-3,
            'pin': $color-23,
            'logo': $color-28,
            'navigation': $color-8,
            'navigation-comment': $color-8,
            'vote': $color-28,
            'gdpr': $color-34,
        ),
    ),
    'EPFL': (
        'text': $color-1,
        'text-in-yes': $color-3,
        'text-in-accent': $color-1,
        'background': $color-2,
        'no': $color-29,
        'yes': $color-30,
        'c1': $color-5,
        'c2': $color-6,
        'c3': $color-7,
        'accent': $color-2,
        'pin': $color-23,
        'logo': $color-1,
        'navigation': $color-1,
        'navigation-comment': $color-1,
        'vote': $color-2,
        'gdpr': $color-35,
        'dark': (
            'text': $color-5,
            'text-in-yes': $color-5,
            'text-in-accent': $color-10,
            'background': $color-11,
            'no': $color-29,
            'yes': $color-30,
            'c1': $color-10,
            'c2': $color-9,
            'c3': $color-8,
            'accent': $color-3,
            'pin': $color-23,
            'logo': $color-29,
            'navigation': $color-8,
            'navigation-comment': $color-8,
            'vote': $color-30,
            'gdpr': $color-35,
        ),
    ),
);

// @include themeColor($property, $color);
@mixin themeColor($property, $color) {
    #{$property}: map-deep-get($theme-colors, 'SpeakUp', $color);
    @media (prefers-color-scheme: dark) {
        #{$property}: map-deep-get($theme-colors, 'SpeakUp', 'dark', $color);
    }

    .theme-UniNE & {
        #{$property}: map-deep-get($theme-colors, 'UniNE', $color);
        @media (prefers-color-scheme: dark) {
            #{$property}: map-deep-get($theme-colors, 'UniNE', 'dark', $color);
        }
    }
    .theme-Unil & {
        #{$property}: map-deep-get($theme-colors, 'Unil', $color);
        @media (prefers-color-scheme: dark) {
            #{$property}: map-deep-get($theme-colors, 'Unil', 'dark', $color);
        }
    }
    .theme-UniGE & {
        #{$property}: map-deep-get($theme-colors, 'UniGE', $color);
        @media (prefers-color-scheme: dark) {
            #{$property}: map-deep-get($theme-colors, 'UniGE', 'dark', $color);
        }
    }
    .theme-EPFL & {
        #{$property}: map-deep-get($theme-colors, 'EPFL', $color);
        @media (prefers-color-scheme: dark) {
            #{$property}: map-deep-get($theme-colors, 'EPFL', 'dark', $color);
        }
    }
}

@mixin themeColorBoxShadow($color) {
    box-shadow: $pixelrounding 0 0 0
            map-deep-get($theme-colors, 'SpeakUp', $color),
        -$pixelrounding 0 0 0 map-deep-get($theme-colors, 'SpeakUp', $color);
    @media (prefers-color-scheme: dark) {
        box-shadow: $pixelrounding 0 0 0
                map-deep-get($theme-colors, 'SpeakUp', 'dark', $color),
            -$pixelrounding 0 0 0 map-deep-get($theme-colors, 'SpeakUp', 'dark', $color);
    }

    .theme-UniNE & {
        box-shadow: $pixelrounding 0 0 0
                map-deep-get($theme-colors, 'UniNE', $color),
            -$pixelrounding 0 0 0 map-deep-get($theme-colors, 'UniNE', $color);
        @media (prefers-color-scheme: dark) {
            box-shadow: $pixelrounding 0 0 0
                    map-deep-get($theme-colors, 'UniNE', 'dark', $color),
                -$pixelrounding 0 0 0 map-deep-get($theme-colors, 'UniNE', 'dark', $color);
        }
    }
    .theme-Unil & {
        box-shadow: $pixelrounding 0 0 0
                map-deep-get($theme-colors, 'Unil', $color),
            -$pixelrounding 0 0 0 map-deep-get($theme-colors, 'Unil', $color);
        @media (prefers-color-scheme: dark) {
            box-shadow: $pixelrounding 0 0 0
                    map-deep-get($theme-colors, 'Unil', 'dark', $color),
                -$pixelrounding 0 0 0 map-deep-get($theme-colors, 'Unil', 'dark', $color);
        }
    }
    .theme-UniGE & {
        box-shadow: $pixelrounding 0 0 0
                map-deep-get($theme-colors, 'UniGE', $color),
            -$pixelrounding 0 0 0 map-deep-get($theme-colors, 'UniGE', $color);
        @media (prefers-color-scheme: dark) {
            box-shadow: $pixelrounding 0 0 0
                    map-deep-get($theme-colors, 'UniGE', 'dark', $color),
                -$pixelrounding 0 0 0 map-deep-get($theme-colors, 'UniGE', 'dark', $color);
        }
    }
    .theme-EPFL & {
        box-shadow: $pixelrounding 0 0 0
                map-deep-get($theme-colors, 'EPFL', $color),
            -$pixelrounding 0 0 0 map-deep-get($theme-colors, 'EPFL', $color);
        @media (prefers-color-scheme: dark) {
            box-shadow: $pixelrounding 0 0 0
                    map-deep-get($theme-colors, 'EPFL', 'dark', $color),
                -$pixelrounding 0 0 0 map-deep-get($theme-colors, 'EPFL', 'dark', $color);
        }
    }
}

@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

$font-size-xs: 1rem * 1; //NOTE: 1.3
$font-size-s: 1rem * 1.5; //NOTE: 1.65
$font-size: 1rem * 2;
$line-height: 1.15;

$duration: 0.8s;
$duration-slider: 0.4s;
$duration-delete: 0.4s;
$easing: ease-in-out;

$horizontal-factor: 1;
$gutter-v: $font-size-xs;
$gutter-h: $gutter-v * $horizontal-factor;
$gutter-v-l: $gutter-v * 2;
$gutter-h-l: $gutter-v-l * $horizontal-factor;
$gutter-v-s: $gutter-v / 2;
$gutter-h-s: $gutter-v-s * $horizontal-factor;
$gutter-v-xs: $gutter-v / 4;
$gutter-h-xs: $gutter-v-xs * $horizontal-factor;

$gutter-iphone: $gutter-h * 2;
$pixelrounding: 2px;

$radius: $gutter-v;

$logo-padding: 0.2rem;
$header-h: 4rem + 2 * $logo-padding;

$picto-w: 3rem;
$picto-h: 3rem;
$picto-tab: $picto-w + 2 * $gutter-v-s;
$line-w: 0.125rem;

$slider-w: 5rem;
$slider-h: 3rem;
$slider-padding: $line-w;

$button-h: $slider-h + $gutter-h * 2;
$button-radius: $button-h/2;

$panel-content-w1: 66%;
$panel-content-w2: 50%;
$panel-information-w1: calc(34% - #{$gutter-v-s});
$panel-information-w2: 25%;

// $hamburger-w: $header-h;
// $hamburger-h: $hamburger-w;
// $hamburger-padding: $gutter-h-s;

$breakpoint-1: 160px;
$breakpoint-2: 320px;
$breakpoint-3: 480px;
$breakpoint-4: 640px;
$breakpoint-5: 800px; // Corresponding breakpoint in css and js
$breakpoint-6: 960px;
$breakpoint-7: 1120px;
$breakpoint-8: 1280px;
$breakpoint-9: 1440px;
// $breakpoint-10: 1600px;
// $breakpoint-11: 1760px;
// $breakpoint-12: 1920px;
// $breakpoint-13: 2080px;
// $breakpoint-14: 2240px;
// $breakpoint-15: 2400px;
// $breakpoint-16: 2560px;
// $breakpoint-17: 2720px;
// $breakpoint-18: 2880px;
// $breakpoint-19: 3040px;
// $breakpoint-20: 3200px;

$breakpoint-height: 500px;

$breakpoint-layout: $breakpoint-5;
$extratop-iphone: 36px;
$extratop-android: 36px;

// Mixins
// --------------------------------
// @include layout-extratop(attribute, value);
@mixin layout-extratop($attribute, $value) {
    .flag-extratop-iphone & {
        #{$attribute}: calc(#{$value} + #{$extratop-iphone});
    }
    .flag-extratop-android & {
        #{$attribute}: calc(#{$value} + #{$extratop-android});
    }
}

// @include vendor-prefix-attribute(attribute, value);
@mixin vendor-prefix-attribute($attribute, $value) {
    -ms-#{$attribute}: $value;
    -o-#{$attribute}: $value;
    -moz-#{$attribute}: $value;
    -webkit-#{$attribute}: $value;
    #{$attribute}: $value;
}

// @include vendor-prefix-value(attribute, value);
@mixin vendor-prefix-value($attribute, $value) {
    #{$attribute}: -ms-#{$value};
    #{$attribute}: -o-#{$value};
    #{$attribute}: -moz-#{$value};
    #{$attribute}: -webkit-#{$value};
    #{$attribute}: $value;
}

@mixin remove-highlight-tap {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

@font-face {
    font-family: 'Antarctica';
    font-weight: 300;
    font-style: normal;
    src: url('../fonts/Antarctica-Beta-VF.ttf') format('truetype');
}

// Extends
// --------------------------------

// ! Development
// ================================

// ! Structure & Styling
// ================================
* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    -webkit-tap-highlight-color: transparent !important;
}
*:active,
*:focus {
    outline: none !important;
    box-shadow: none !important;
}

html {
    width: 100%;
    // height: 100%;

    font-size: 2.5vw;
    @media screen and (min-width: $breakpoint-4) {
        font-size: 2vw;
    }
    @media screen and (min-width: $breakpoint-5) {
        font-size: 1.2vw;
    }
    @media screen and (min-width: $breakpoint-6) {
        font-size: 1.1vw;
    }
    @media screen and (min-width: $breakpoint-6) {
        font-size: 1vw;
    }
    @media screen and (min-width: $breakpoint-7) {
        font-size: 0.9vw;
    }
    @media screen and (min-width: $breakpoint-8) {
        font-size: 0.8vw;
    }
    @media screen and (min-width: $breakpoint-9) {
        font-size: 0.7vw;
    }
}

body {
    width: 100%;
    //   height: 100%;
    text-align: left;

    font-size: $font-size;
    font-family: 'Antarctica', sans-serif;
    font-variation-settings: 'wght' 300, 'wdth' 94, 'CNTR' 0;
    // font-variation-settings: 'wght' 250, 'wdth' 94, 'cntr' 0;
    @include themeColor('background-color', 'background');
    @include themeColor('color', 'text');
    line-height: $line-height;

    -webkit-font-smoothing: antialiased;
    // -webkit-font-smoothing: subpixel-antialiased;
    // -webkit-font-kerning: none;
    // -webkit-font-variant-ligatures: none;
    // -webkit-font-feature-settings: none;

    // text-rendering: optimizeLegibility;
    // text-rendering: geometricPrecision;

    @include vendor-prefix-attribute(text-size-adjust, none);
}

hr {
    display: none;
}

a {
    @include themeColor('color', 'text');
    text-decoration: none;
}
a:link,
a:visited {
    text-decoration: none;
}
a:hover,
a:active {
    text-decoration: none;
    @include themeColor('color', 'text');
}
a:focus {
    text-decoration: none;
}
// .current-parent {}
// .current {}

input,
button,
select,
textarea {
    @include vendor-prefix-attribute(text-size-adjust, none);
    @include vendor-prefix-attribute(border-radius, 0);
    @include vendor-prefix-attribute(appearance, none);
}

.assistive-text {
    display: none;
}
html {
    height: 100%;
}
body {
    width: 100%;
    // height: 100%;
}

// General
// ================================

.flag-transitions-false {
    * {
        transition: none !important;
    }
}

// Modal
// --------------------------------
.modal {
    display: none;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    @include themeColor('background-color', 'background');
    z-index: 450;

    // display: none;
    .entry-input-nickname {
        position: fixed;
        top: 50%;
        left: 50%;
        width: calc(100% - #{$gutter-v-s * 2});
        height: 200px;

        transform: translate3d(-50%, -50%, 0);
        @media screen and (min-width: $breakpoint-layout) {
            width: $panel-content-w2;
        }
        .form {
            position: relative;
            height: 2 * $font-size * $line-height;
            margin-bottom: $gutter-h;
            transform: scale(0);
            transition: transform $duration $easing;
        }
        .button-room {
            position: relative;
            height: $button-h;
            padding: $gutter-h $gutter-v;
            border-radius: $button-h/2;
            font-family: 'Antarctica', sans-serif;
            font-size: $font-size-xs;
            text-align: center;
            @include themeColor('color', 'text');

            outline: none;
            border: none;
            cursor: pointer;
            @include remove-highlight-tap;
            transform: scale(0);
            transition: transform $duration $easing;

            @media screen and (min-width: $breakpoint-layout) {
                font-size: $font-size;
            }
            // .switch-information-container-on.switch-remove-room-on & {
            //   transform: scale(1);
            // }
            span {
                position: absolute;
                top: 50%;
                left: 0%;
                width: 100%;
                transform: translate3d(0%, -45%, 0);
            }
            &.cancel {
                @include themeColor('background-color', 'c3');
                margin-bottom: $gutter-h-s;
            }
            &.delete {
                @include themeColor('background-color', 'no');
            }

            &.leave {
                @include themeColor('background-color', 'no');
            }

            &.clear {
                @include themeColor('background-color', 'no');
            }

            &.confirm {
                @include themeColor('background-color', 'yes');
            }
        }
    }
    .switch-modal & {
        display: block;
        .entry-input-nickname {
            .form,
            .button-room {
                transform: scale(1);
            }
        }
        // .container-content {
        // }
    }
    .switch-modal-off & {
        .entry-input-nickname {
            .form,
            .button-room {
                transform: scale(0);
            }
        }
    }
}

// GDPR Notice
// --------------------------------

.gdpr-notice {
    display: none;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include themeColor('background-color', 'gdpr');
    .switch-gdpr & {
        display: block;
    }

    .entry-notice {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        max-height: 50vh;
        padding: $gutter-v-l 0;
        transition: max-height $duration-slider $easing;
        transform: translate3d(-50%, -50%, 0);
        @media screen and (min-width: $breakpoint-layout) {
            width: 50%;
            padding: 0;
        }
        @media screen and (max-height: $breakpoint-height) {
            width: 100%;
            padding: 0;
        }

        &.switch-gdpr-further-content-open {
            max-height: 100vh;
            height: 100%;
            @media screen and (min-width: $breakpoint-layout) {
                max-height: 100vh;
                height: auto;
            }
            @media screen and (max-height: $breakpoint-height) {
                max-height: 100vh;
                height: 100%;
            }
        }

        .entry-notice-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            max-height: unset;
            margin: 0 auto;
            padding: $gutter-h-l;
            border-radius: $radius;
            @include themeColor('background-color', 'c2');
            transition: height $duration-slider $easing;
            @media screen and (max-height: $breakpoint-height) {
                max-height: 100vh;
            }

            .language-select-gdpr {
                margin: $gutter-h-l auto 0 auto;
                text-align: center;
                font-size: $font-size-s;

                .language {
                    margin: 0 $gutter-v;
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                    &:active {
                        text-decoration: none;
                    }
                    &.selected {
                        text-decoration: underline;
                        &:active {
                            text-decoration: none;
                        }
                    }
                }
            }

            .entry-notice-content-terms {
                display: flex;
                flex: 1 1 auto;
                flex-direction: column;
                overflow: auto;
                margin: 0 0 $gutter-v 0;

                h3 {
                    margin: 0 0 $gutter-h-l 0;
                    text-align: center;
                }
                ul {
                    margin: 0 0 $gutter-v 0;
                    li {
                        position: relative;
                        margin: 0;
                        padding: 0 0 0 $gutter-v-l;
                        &:before {
                            content: '–';
                            display: block;
                            position: absolute;
                            left: 0;
                            width: $gutter-v;
                            overflow: hidden;
                        }
                    }
                }
            }

            .button-further-content {
                display: block;
                position: relative;
                height: $button-h;
                min-height: $button-h;
                width: 100%;
                margin: $gutter-h auto 0 auto;
                font-size: $font-size;
                text-align: center;
                @include themeColor('color', 'c1');
                @include themeColor('background-color', 'c3');
                outline: none;
                border: none;
                cursor: pointer;
                border-radius: $button-h/2;
                @include remove-highlight-tap;
                transition: height $duration-slider $easing,
                    border-radius $duration-slider $easing;
                span {
                    position: absolute;
                    top: 50%;
                    left: 0%;
                    width: 100%;
                    transform: translate3d(0%, -45%, 0);
                }
            }

            .switch-button-further-content {
                overflow: visible;
                height: 100%;
                background-color: transparent;
                border-radius: 0;
                cursor: default;
                @media screen and (min-width: $breakpoint-layout) {
                    overflow: auto;
                    height: 50vh;
                }
                @media screen and (max-height: $breakpoint-height) {
                    overflow: visible;
                    height: 100%;
                }
                > span {
                    display: none;
                }
                div {
                    display: block;
                }
            }

            .gdpr-further-content {
                display: none;
                overflow: visible;
                width: 100%;
                margin: 0;
                padding: $gutter-v-l;
                @include themeColor('background-color', 'c3');
                border-radius: $button-radius;
                text-align: left;
                font-size: $font-size-s;
                @media screen and (min-width: $breakpoint-layout) {
                    overflow: auto;
                    font-size: $font-size-s;
                }
                @media screen and (max-height: $breakpoint-height) {
                    overflow: visible;
                    font-size: $font-size-s;
                }
                h4 {
                    font-size: $font-size;
                    margin: $gutter-v 0 0 0;
                    &:first-child {
                        margin: 0;
                    }
                }
                p {
                    text-align: left;
                    width: 100%;
                }
                ul {
                    li {
                        position: relative;
                        margin: 0;
                        padding: 0 0 0 $gutter-v * 1.5;
                        &:before {
                            content: '–';
                            display: block;
                            position: absolute;
                            left: 0;
                            width: $gutter-v;
                            overflow: hidden;
                        }
                    }
                    :last-child {
                        margin: 0;
                    }
                }
            }

            .button-room {
                display: block;
                position: relative;
                width: 100%;
                height: $button-h;
                min-height: $button-h;
                margin: 0 auto 0 auto;
                padding: $gutter-h $gutter-v;
                font-family: 'Antarctica', sans-serif;
                font-size: $font-size;
                text-align: center;
                border-radius: $button-h/2;
                @include themeColor('color', 'c1');
                @include themeColor('background-color', 'yes');
                outline: none;
                border: none;
                cursor: pointer;
                @include remove-highlight-tap;
                span {
                    position: absolute;
                    top: 50%;
                    left: 0%;
                    width: 100%;
                    transform: translate3d(0%, -45%, 0);
                }
            }
        }
    }
}

// .gdpr-further-content-open {
//   .entry-notice {
//     max-height: 100vh;
//     height: 100%;
//     @media screen and (min-width: $breakpoint-layout) {
//       max-height: 100vh;
//       height: auto;
//     }
//   }
// }
// Header
// --------------------------------
.header {
    position: fixed;
    z-index: 500;
    width: 100%;
    height: $header-h;
    @include themeColor('background-color', 'background');

    &::after {
        content: '';
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        .flag-extratop-iphone & {
            display: block;
            height: $extratop-iphone;
        }
        .flag-extratop-android & {
            display: block;
            height: $extratop-android;
        }

        @include themeColor('background-color', 'background');
        z-index: 200;
    }

    h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% - 6em);
        @include themeColor('color', 'navigation');
        text-align: center;
        transform: translate3d(-50%, -50%, 0);
        .admin-badge {
            display: none;
            padding-left: $gutter-v-s;
            font-size: $font-size-xs;
            .flag-admin & {
                display: inline-block;
            }
        }
    }
    .button-back {
        position: absolute;
        left: 0;
        top: 0;
        width: $picto-w + $gutter-v-s * 2;
        height: $picto-h + $gutter-h-s * 2;
        padding: $gutter-h-s + $line-w $gutter-v-s;
        cursor: pointer;
        @include remove-highlight-tap;
        svg {
            width: 100%;
            height: 100%;
            fill: none;
            @include themeColor('stroke', 'navigation');
            stroke-width: $line-w;
            overflow: visible;
        }
    }
    .logo {
        position: absolute;
        bottom: 0;
        left: $gutter-v-s * 3;
        width: $picto-w;
        height: $header-h - $gutter-h-s;
        cursor: pointer;
        @include remove-highlight-tap;

        @media screen and (min-width: $breakpoint-layout) {
            left: $gutter-v-s;
        }

        svg {
            width: 100%;
            height: 100%;
            overflow: visible;
            @include themeColor('fill', 'logo');
        }

        .flag-loading & {
            transform-origin: 50% 50%;
            animation: loading-animation 3s ease-in-out 0s infinite forwards
                reverse;
        }
    }
    @keyframes loading-animation {
        0% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(-90deg);
        }
        50% {
            transform: rotate(-180deg);
        }
        75% {
            transform: rotate(-270deg);
        }
        100% {
            transform: rotate(-360deg);
        }
    }

    .button-information {
        position: absolute;
        z-index: 600;
        right: 0;
        top: 0;
        width: $picto-w + $gutter-v-s * 2;
        height: $picto-h + $gutter-h-s * 2;
        padding: $gutter-h-s + $line-w $gutter-v-s;
        cursor: pointer;
        @include remove-highlight-tap;
        .open {
            display: block;
            .switch-information-container-on & {
                display: none;
            }
        }
        .close {
            display: none;
            .switch-information-container-on & {
                display: block;
            }
        }
        svg {
            width: 100%;
            height: 100%;
            fill: none;
            @include themeColor('stroke', 'navigation');
            stroke-width: $line-w;
            overflow: visible;
        }
        .switch-modal & {
            display: none;
        }
    }
}

// Container Information
// --------------------------------
.container-information {
    position: fixed;
    z-index: 1100;
    overflow: auto;
    top: $header-h;

    left: calc(100% + 0 *#{$gutter-v-s});
    width: calc(100% - 2 * #{$gutter-v-s});
    max-height: calc(100% - #{$header-h});
    .flag-extratop-iphone & {
        top: calc(#{$header-h} + #{$extratop-iphone});
        max-height: calc(100% - #{$header-h} - #{$extratop-iphone});
    }
    .flag-extratop-android & {
        top: calc(#{$header-h} + #{$extratop-android});
        max-height: calc(100% - #{$header-h} - #{$extratop-android});
    }
    padding: $gutter-h $gutter-v;

    font-size: $font-size-s;
    @include themeColor('background-color', 'c2');

    transform: translate3d(0%, 0%, 0);
    transition: transform $duration $easing;
    .switch-information-container-on & {
        transform: translate3d(calc(-100% - 1 * #{$gutter-v-s}), 0%, 0);
    }
    .switch-information-container-on.switch-remove-room-on & {
        transform: translate3d(0%, 0%, 0);
    }

    .switch-information-container-on.switch-leave-room-on & {
        transform: translate3d(0%, 0%, 0);
    }

    .switch-information-container-on.switch-clear-room-on & {
        transform: translate3d(0%, 0%, 0);
    }
    // @media screen and (min-width: $breakpoint-5) {
    //   z-index: 0;
    //   left: 100%;
    //   width: $panel-information-w1;
    //   .switch-information-container-on & {
    //     transform: translate3d(calc(-100%), 0%, 0);
    //   }
    // }

    @media screen and (min-width: $breakpoint-layout) {
        z-index: 0;
        left: 100%;
        width: $panel-information-w2;
        .switch-information-container-on & {
            transform: translate3d(calc(-50% - 25vw + #{$gutter-v-s}), 0%, 0);
        }
        // .switch-information-container-on.switch-remove-room-on & {
        //   transform: translate3d(0%, 0%, 0);
        // }
    }

    h2 {
        font-size: $font-size;
    }

    .statistics {
        display: none;
        padding: $gutter-h 0;
        .flag-admin & {
            display: block;
        }
    }
    .comments-allowed {
        display: none;
        .flag-admin & {
            display: block;
        }
    }
    .entry-switch {
        .label {
            &:last-of-type {
                margin-bottom: $gutter-h-l;
            }
        }
    }
    h4 {
        font-size: $font-size;
        width: calc(100% - #{$slider-w} - #{$gutter-v-s});
    }
}

// Container Content
// --------------------------------
.container-content {
    position: relative;
    z-index: 200;
    top: $header-h;
    left: 50%;
    width: 100%;
    padding: 0 $gutter-v-s;
    transform: translate3d(-50%, 0%, 0);
    transition: transform $duration $easing;

    .switch-modal &,
    .switch-remove-room-on &,
    .switch-leave-room-on &,
    .switch-clear-room-on &,
    .switch-information-container-on & {
        transform: translate3d(calc(-150% + 1 * #{$gutter-v-s}), 0%, 0);
    }

    @media screen and (min-width: $breakpoint-layout) {
        width: $panel-content-w2;
        padding: 0;
        .switch-information-container-on & {
            transform: translate3d(
                -50% - (0.25 * (100% - $panel-content-w2) *
                            (100 / $panel-content-w2)),
                0%,
                0
            );
        }
        .switch-modal &,
        .switch-information-container-on.switch-remove-room-on & {
            transform: translate3d(-200%, 0%, 0);
        }
        .switch-information-container-on.switch-leave-room-on & {
            transform: translate3d(-200%, 0%, 0);
        }
        .switch-information-container-on.switch-clear-room-on & {
            transform: translate3d(-200%, 0%, 0);
        }
    }
}

// Switches
// --------------------------------
.entry-switch {
    input {
        width: 100%;
        margin-top: $gutter-h;
        padding: $gutter-h $gutter-v;
        font-size: $font-size;
        border: none;
        outline: none;
    }
    .label {
        position: relative;
        margin-top: $gutter-h;
        padding: $gutter-h 0;

        p {
            font-size: $font-size-xs;
        }
        .switch {
            position: absolute;
            display: inline-block;
            top: 50%;
            right: 0;
            transform: translate3d(0, -50%, 0);
            width: $slider-w;
            height: $slider-h;
            input {
                opacity: 0;
                width: 0;
                height: 0;
            }
        }
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            @include themeColor('background-color', 'no');
            transition: $duration-slider;
            cursor: pointer;
            // @include remove-highlight-tap;
            &.round {
                border-radius: $slider-h / 2;
                &::before {
                    border-radius: 50%;
                }
            }
            &::before {
                position: absolute;
                content: '';
                top: 50%;
                left: 0;
                width: $slider-h - $slider-padding * 2;
                height: $slider-h - $slider-padding * 2;
                @include themeColor('background-color', 'c1');
                transform: translate3d($slider-padding, -50%, 0);
                transition: $duration-slider;
            }
        }

        input:checked + .slider {
            @include themeColor('background-color', 'yes');
        }

        // input:focus + .slider {
        //   box-shadow: 0 0 1px $color-g;
        // }

        input:checked + .slider:before {
            transform: translate3d(
                $slider-w - $slider-h + $slider-padding,
                -50%,
                0
            );
        }
    }
}

// Input
// --------------------------------

.form {
    input {
        position: relative;
        width: 100%;
        padding: $gutter-h $gutter-v;
        font-family: 'Antarctica', sans-serif;
        font-size: $font-size;
        border: none;
        outline: none;
    }
    input[type='text'] {
        @include themeColor('background-color', 'c1');
        @include themeColor('color', 'text');
    }

    input::placeholder {
        @include themeColor('color', 'c2');
    }
    textarea {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2 * $font-size * $line-height;
        padding: $gutter-h-s $gutter-v;
        font-family: 'Antarctica', sans-serif;
        font-size: $font-size-s;
        border: none;
        outline: none;
        resize: none;
        transform: translate3d(0, -50%, 0);
        @include themeColor('background-color', 'c1');
        @include themeColor('color', 'text');
    }
    textarea::placeholder {
        font-size: $font-size-s;
        @include themeColor('color', 'c2');
    }

    //NOTE: Rmove spinner
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    //-----
}

// Buttons
// --------------------------------
.button-submit {
    position: relative;
    width: 100%;
    height: $button-h;
    margin-top: $gutter-h;
    padding: $gutter-h $gutter-v;
    border-radius: $button-h/2;
    font-family: 'Antarctica', sans-serif;
    font-size: $font-size;
    text-align: left;
    @include themeColor('color', 'text-in-yes');
    @include themeColor('background-color', 'yes');
    outline: none;
    border: none;
    cursor: pointer;
    @include remove-highlight-tap;

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -45%, 0);
    }
}

.profile-buttons,
.room-buttons {
    .container-information & {
        margin-top: $gutter-h;
        .flag-admin & {
            margin-top: 0;
        }
    }
    .button {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: $button-h;
        // margin-top: $gutter-h-s;
        font-size: $font-size;
        @include themeColor('color', 'text');
        transition: transform $duration $easing;
        border-radius: $button-radius;
        cursor: pointer;
        @include remove-highlight-tap;
        transition: transform $duration-delete $easing,
            width $duration-delete $easing;

        .flag-admin & {
            margin-top: $gutter-h-s;
        }

        p {
            position: absolute;
            overflow: hidden;
            top: 50%;
            left: 0;
            width: 100%;
            padding: 0 $gutter-v;

            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            transform: translate3d(0%, -50%, 0);
        }

        &.button-code {
            position: relative;
            display: none;
            @include themeColor('background-color', 'c3');
            transition: border-radius $duration-slider $easing;
            .flag-admin & {
                display: block;
            }
            &.button-id {
                display: block;
            }
            .admin-key {
                display: none;
            }
            .admin-key-close {
                display: none;
                position: absolute;
                top: 50%;
                right: 0;
                width: $button-h;
                height: $button-h;
                padding: $gutter-h $gutter-v;
                transform: translate3d(0%, -50%, 0);
                svg {
                    width: 100%;
                    height: 100%;
                    fill: none;
                    @include themeColor('stroke', 'text');
                    stroke-width: $line-w;
                    overflow: visible;
                }
            }

            &.switch-on {
                border-radius: 0;
                .button-text {
                    display: none;
                }
                .admin-key {
                    position: relative;
                    display: block;
                    user-select: all;
                    font-size: $font-size-xs;
                }
                .admin-key-close {
                    display: block;
                }
            }
        }

        &.button-qrcode {
            height: auto;
            // border: solid 1px red;
            @include themeColor('background-color', 'c3');
            margin-bottom: $gutter-h-s;
            @include themeColor('color', 'text');
            border-radius: $button-radius;
            transition: border-radius $duration $easing;

            &.switch-on {
                border-radius: $radius;

                // .button-text {
                //   display: none;
                // }
            }
            .entry-title {
                position: relative;
                height: $button-h;
                border-radius: $button-radius;
                cursor: pointer;
                @include remove-highlight-tap;
                h3 {
                    position: absolute;
                    overflow: hidden;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    padding: 0 $gutter-v;

                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    transform: translate3d(0%, -50%, 0);
                }
            }
            .entry-switch {
                padding: 0 $gutter-v $gutter-h-l $gutter-v;
            }
            .qrcode {
                position: relative;
                width: 100%;
                height: calc(25vw - 2 * #{$gutter-v});
                overflow: hidden;

                img {
                    width: 100%;
                    height: auto;
                    padding: 0 $gutter-v 0 $gutter-v;
                }
            }
        }
        &.button-export {
            @include themeColor('background-color', 'c3');
            .entry-rooms .entry-room.guest &,
            .container-information & {
                display: none;
            }
            .flag-admin .container-information & {
                display: block;
            }
        }
        &.button-leave {
            @include themeColor('background-color', 'no');

            .confirm {
                display: none;
            }
        }
        &.button-clear {
            @include themeColor('background-color', 'no');

            .confirm {
                display: none;
            }
        }
        &.button-delete {
            @include themeColor('background-color', 'no');
            .entry-rooms .entry-room.guest &,
            .container-information & {
                display: none;
            }
            .flag-admin .container-information & {
                display: block;
            }
            .confirm {
                display: none;
            }
        }
    }
}
.button-cancel {
    position: absolute;
    left: $gutter-v-l + $gutter-v-s;
    width: calc(50% - #{$gutter-v-l + $gutter-v-s});
    height: $button-h;
    padding: $gutter-h $gutter-v;
    border-radius: $button-h/2;
    font-family: 'Antarctica', sans-serif;
    font-size: $font-size-xs;
    text-align: left;
    @include themeColor('color', 'text');
    @include themeColor('background-color', 'c3');
    outline: none;
    border: none;
    cursor: pointer;
    @include remove-highlight-tap;

    transform: scale(0);
    transition: transform $duration-delete $easing;
    @media screen and (min-width: $breakpoint-layout) {
        left: 0;
        width: 50%;
    }

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -45%, 0);
    }
}

// Drop Down
// --------------------------------
.custom-select {
    width: 100%;

    select {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: $button-h;
        padding: 0 $gutter-v-l;
        font-family: 'Antarctica', sans-serif;
        font-size: $font-size;
        text-align-last: center;
        border-radius: $button-radius;
        outline: none;
        border: none;
        &.theme {
            @include themeColor('background-color', 'background');
            @include themeColor('color', 'logo');
        }
        &.language {
            @include themeColor('background-color', 'c3');
            @include themeColor('color', 'text');
        }
    }
}

// Template Lobby
// ================================
.template-lobby {
    .header {
        .button-back {
            display: none;
        }
    }
    .container-information {
        padding-bottom: $gutter-h-l;
        h2 {
            margin: 0 0 $gutter-h 0;
        }
        .input-import {
            margin: $gutter-h 0 $gutter-h 0;
        }
        .custom-select {
            &:not(:last-child) {
                margin-bottom: $gutter-h-l;
            }
            label + select {
                margin-top: $gutter-h-s;
            }
        }
        .lobby-choices.id {
            margin-bottom: $gutter-h-s;
            font-size: $font-size;
            @include themeColor('background-color', 'c3');
            @include themeColor('color', 'text');
            border-radius: $button-radius;
            transition: border-radius $duration $easing;
            &.switch-selected {
                border-radius: $radius;
            }
            .entry-title {
                position: relative;
                height: $button-h;
                border-radius: $button-radius;
                cursor: pointer;
                @include remove-highlight-tap;
                h3 {
                    position: absolute;
                    overflow: hidden;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    padding: 0 $gutter-v;

                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    transform: translate3d(0%, -50%, 0);
                }
            }
            .caption {
                margin-top: $gutter-h;
                font-size: $font-size-s;
            }
            .button-submit {
                @include themeColor('background-color', 'no');
                @include themeColor('color', 'c1');
            }
            .entry-switch {
                padding: 0 $gutter-v $gutter-h-l $gutter-v;
            }
        }
    }

    .entry-lobby {
        padding-bottom: $gutter-h-l;
        .lobby-choices {
            @include themeColor('background-color', 'c2');
            margin-bottom: $gutter-h-s;
            @include themeColor('color', 'text');
            border-radius: $button-radius;
            transition: border-radius $duration $easing;
            &.switch-selected {
                border-radius: $radius;
            }
            .entry-title {
                position: relative;
                height: $button-h;
                border-radius: $button-radius;
                cursor: pointer;
                @include remove-highlight-tap;
                h3 {
                    position: absolute;
                    overflow: hidden;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    padding: 0 $gutter-v;

                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    transform: translate3d(0%, -50%, 0);
                }
            }
            .entry-switch {
                padding: 0 $gutter-v $gutter-h-l $gutter-v;
            }
        }
        .container-rooms {
            .entry-rooms {
                padding-top: $gutter-h-l;
                @include themeColor('color', 'navigation');

                &.switch-close {
                    display: none;
                }
                h4 {
                    font-size: $font-size-s;
                    padding-bottom: $gutter-h-s;
                }
                .entry-room {
                    position: relative;
                    margin-bottom: $gutter-h-s;
                    @include themeColor('color', 'text');
                    &.switch-options-on {
                        .room-title {
                            transform: translate(-75%);
                        }
                        &.switch-remove {
                            .room-title {
                                transform: translate3d(
                                    calc(-100% - #{$gutter-v}),
                                    0,
                                    0
                                );
                                transition: transform $duration-delete $easing;
                                @media screen and (min-width: $breakpoint-layout) {
                                    transform: translate3d(-150%, 0, 0);
                                }
                            }
                            .button {
                                transform: scale(0);
                                transition: transform $duration-delete $easing,
                                    width $duration-delete $easing;
                            }
                        }
                        &.switch-delete-1 {
                            .room-title {
                                transform: translate3d(
                                    calc(-100% + #{$gutter-v-l}),
                                    0,
                                    0
                                );
                                transition: transform $duration-delete $easing;
                                @media screen and (min-width: $breakpoint-layout) {
                                    transform: translate3d(
                                        calc(-100% - #{$gutter-v-s}),
                                        0,
                                        0
                                    );
                                }
                            }

                            .room-buttons {
                                width: 50%;
                                left: 50%;
                            }
                            .button {
                                &:not(.button-delete) {
                                    transform: scale(0);
                                    width: 0;
                                    margin: 0;
                                }
                            }
                            .button-delete {
                                width: 100%;
                                .delete {
                                    display: none;
                                }
                                .confirm {
                                    display: block;
                                }
                            }

                            .button-leave {
                                width: 100%;
                                .leave {
                                    display: none;
                                }
                                .confirm {
                                    display: block;
                                }
                            }

                            .button-clear {
                                width: 100%;
                                .clear {
                                    display: none;
                                }
                                .confirm {
                                    display: block;
                                }
                            }

                            .button-cancel {
                                transform: scale(1);
                            }
                        }

                        &.switch-leave-1 {
                            .room-title {
                                transform: translate3d(
                                    calc(-100% + #{$gutter-v-l}),
                                    0,
                                    0
                                );
                                transition: transform $duration-delete $easing;
                                @media screen and (min-width: $breakpoint-layout) {
                                    transform: translate3d(
                                        calc(-100% - #{$gutter-v-s}),
                                        0,
                                        0
                                    );
                                }
                            }

                            .room-buttons {
                                width: 50%;
                                left: 50%;
                            }
                            .button {
                                &:not(.button-leave) {
                                    transform: scale(0);
                                    width: 0;
                                    margin: 0;
                                }
                            }
                            .button-leave {
                                width: 100%;
                                .leave {
                                    display: none;
                                }
                                .confirm {
                                    display: block;
                                }
                            }
                            .button-cancel {
                                transform: scale(1);
                            }
                        }

                        &.switch-clear-1 {
                            .room-title {
                                transform: translate3d(
                                    calc(-100% + #{$gutter-v-l}),
                                    0,
                                    0
                                );
                                transition: transform $duration-delete $easing;
                                @media screen and (min-width: $breakpoint-layout) {
                                    transform: translate3d(
                                        calc(-100% - #{$gutter-v-s}),
                                        0,
                                        0
                                    );
                                }
                            }

                            .room-buttons {
                                width: 50%;
                                left: 50%;
                            }
                            .button {
                                &:not(.button-clear) {
                                    transform: scale(0);
                                    width: 0;
                                    margin: 0;
                                }
                            }
                            .button-clear {
                                width: 100%;
                                .clear {
                                    display: none;
                                }
                                .confirm {
                                    display: block;
                                }
                            }
                            .button-cancel {
                                transform: scale(1);
                            }
                        }
                    }
                    .room-buttons {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        position: absolute;
                        z-index: 50;
                        top: 0;
                        left: 25%;
                        width: 75%;
                        height: 100%;
                        transition: width $duration-delete $easing,
                            left $duration-delete $easing;

                        .button {
                            flex: 1 1 auto;
                            width: calc(100% / 6);
                            margin-top: 0;
                            margin-left: $gutter-v-s;
                            font-size: $font-size-xs;

                            cursor: pointer;
                            p {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                @include themeColor('color', 'text');
                                transform: translate3d(-50%, -50%, 0);
                            }
                        }
                    }
                    .room-title {
                        position: relative;
                        z-index: 100;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: $gutter-h $gutter-v;
                        height: $button-h;
                        @include themeColor('background-color', 'c1');
                        border-radius: $radius;
                        transition: transform $duration $easing;
                        a {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            p {
                                position: absolute;
                                overflow: hidden;
                                top: 50%;
                                left: 0;
                                width: 100%;
                                padding: 0 $gutter-v;

                                text-align: center;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                transform: translate3d(0%, -50%, 0);
                                .admin-badge {
                                    display: inline-block;
                                    margin-left: $gutter-v-s;
                                    font-size: $font-size-xs;
                                }
                                .room-new-message-counter {
                                    display: inline-block;
                                    vertical-align: top;
                                    height: $gutter-v;
                                    width: $gutter-h;
                                    margin-left: $gutter-v-s;
                                    border-radius: 50%;
                                    @include themeColor(
                                        'background-color',
                                        'no'
                                    );
                                }
                            }
                        }

                        .button-more {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: $picto-tab;
                            height: 100%;
                            cursor: pointer;
                            @include remove-highlight-tap;
                            p {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate3d(-50%, -50%, 0);
                                width: $picto-w;
                                height: $picto-h;
                                svg {
                                    width: 100%;
                                    height: 100%;
                                    fill: none;
                                    @include themeColor('stroke', 'text');
                                    stroke-width: $line-w;
                                    overflow: visible;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Template Messages
// ================================
.template-messages {
    // General
    // --------------------------------
    .header {
        .logo {
            display: none;
            left: $gutter-v-s + $picto-w;
        }
    }
    .menu-delete-room {
        position: fixed;
        top: 50%;
        left: 50%;
        width: calc(100% - #{$gutter-v-s * 2});
        height: 200px;

        transform: translate3d(-50%, -50%, 0);
        @media screen and (min-width: $breakpoint-layout) {
            width: $panel-content-w2;
        }
        .button-room {
            position: relative;
            height: $button-h;
            padding: $gutter-h $gutter-v;
            border-radius: $button-h/2;
            font-family: 'Antarctica', sans-serif;
            font-size: $font-size-xs;
            text-align: left;
            @include themeColor('color', 'text');

            outline: none;
            border: none;
            cursor: pointer;
            @include remove-highlight-tap;
            transform: scale(0);
            transition: transform $duration $easing;

            @media screen and (min-width: $breakpoint-layout) {
                font-size: $font-size;
            }
            .switch-information-container-on.switch-remove-room-on & {
                transform: scale(1);
            }
            .switch-information-container-on.switch-leave-room-on & {
                transform: scale(1);
            }
            .switch-information-container-on.switch-clear-room-on & {
                transform: scale(1);
            }
            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -45%, 0);
            }
            &.cancel {
                @include themeColor('background-color', 'c3');
                margin-bottom: $gutter-h-s;
            }
            &.delete {
                @include themeColor('background-color', 'no');
            }
        }
    }

    .menu-leave-room {
        position: fixed;
        top: 50%;
        left: 50%;
        width: calc(100% - #{$gutter-v-s * 2});
        height: 200px;

        transform: translate3d(-50%, -50%, 0);
        @media screen and (min-width: $breakpoint-layout) {
            width: $panel-content-w2;
        }
        .button-room {
            position: relative;
            height: $button-h;
            padding: $gutter-h $gutter-v;
            border-radius: $button-h/2;
            font-family: 'Antarctica', sans-serif;
            font-size: $font-size-xs;
            text-align: left;
            @include themeColor('color', 'text');

            outline: none;
            border: none;
            cursor: pointer;
            @include remove-highlight-tap;
            transform: scale(0);
            transition: transform $duration $easing;

            @media screen and (min-width: $breakpoint-layout) {
                font-size: $font-size;
            }
            .switch-information-container-on.switch-leave-room-on & {
                transform: scale(1);
            }
            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -45%, 0);
            }
            &.cancel {
                @include themeColor('background-color', 'c3');
                margin-bottom: $gutter-h-s;
            }
            &.leave {
                @include themeColor('background-color', 'no');
            }
        }
    }

    .menu-clear-room {
        position: fixed;
        top: 50%;
        left: 50%;
        width: calc(100% - #{$gutter-v-s * 2});
        height: 200px;

        transform: translate3d(-50%, -50%, 0);
        @media screen and (min-width: $breakpoint-layout) {
            width: $panel-content-w2;
        }
        .button-room {
            position: relative;
            height: $button-h;
            padding: $gutter-h $gutter-v;
            border-radius: $button-h/2;
            font-family: 'Antarctica', sans-serif;
            font-size: $font-size-xs;
            text-align: left;
            @include themeColor('color', 'text');

            outline: none;
            border: none;
            cursor: pointer;
            @include remove-highlight-tap;
            transform: scale(0);
            transition: transform $duration $easing;

            @media screen and (min-width: $breakpoint-layout) {
                font-size: $font-size;
            }
            .switch-information-container-on.switch-clear-room-on & {
                transform: scale(1);
            }
            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -45%, 0);
            }
            &.cancel {
                @include themeColor('background-color', 'c3');
                margin-bottom: $gutter-h-s;
            }
            &.clear {
                @include themeColor('background-color', 'no');
            }
        }
    }

    .input-button {
        position: absolute;
        top: 50%;
        width: $picto-w;
        height: $picto-h;
        transform: translate3d(0, -50%, 0);
        cursor: pointer;
        @include remove-highlight-tap;

        &.plus {
            display: none;
            left: $gutter-v-s;
            .flag-admin & {
                display: block;
                // left: $gutter-v-s;
            }
            .open {
                display: block;
            }
            .close {
                display: none;
            }
        }
        &.submit {
            right: $gutter-v-s;
        }
        svg {
            width: 100%;
            height: 100%;
            overflow: visible;
            fill: none;
            stroke-width: $line-w;
            @include themeColor('stroke', 'navigation');
            cursor: pointer;
        }
    }

    .container-information {
        .custom-select {
            margin-top: $gutter-h;
        }
    }

    // Container Content
    // --------------------------------
    .container-content {
        .sorting {
            @include vendor-prefix-attribute(position, sticky);
            display: flex;
            justify-content: space-evenly;
            top: $header-h;
            height: $font-size-xs * $line-height + 2 * $gutter-h-s;
            font-size: $font-size-xs;
            @include themeColor('background-color', 'c2');
            @include themeColor('color', 'text');
            z-index: 200;

            @include layout-extratop('top', $header-h);

            li {
                padding: $gutter-h-s $gutter-v-s;
                cursor: pointer;
                &.switch-selected {
                    @include themeColor('color', 'accent');
                }
                &.moderate {
                    display: none;
                    .notification {
                        display: inline-block;
                        width: $gutter-v-s;
                        height: $gutter-h-s;
                        @include themeColor('background-color', 'no');
                        border-radius: 50%;
                        transform: translateY(-100%);
                    }
                    &.switch-off {
                        .notification {
                            visibility: hidden;
                        }
                    }
                    .flag-admin & {
                        display: block;
                    }
                }
            }
        }
        .load-new-message {
            display: none;
            @include vendor-prefix-attribute(position, sticky);
            top: $header-h + $font-size-xs * $line-height + 3 * $gutter-h-s;
            margin-bottom: $gutter-h-s;
            z-index: 1300;
            @include layout-extratop(
                'top',
                $header-h + $font-size-xs * $line-height + 3 * $gutter-h-s
            );
            .flag-new-messages & {
                display: block;
            }
            .button-submit {
                margin: 0;
                @include themeColor('background-color', 'c3');
                .new-message-text {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    text-align: center;
                    transform: translate3d(-50%, -45%, 0);
                    .new-message-counter {
                        position: relative;
                        left: 0;
                        transform: none;
                    }
                }
            }
        }
        .flash-notification {
            @extend .load-new-message;

            .flag-new-messages & {
                display: none;
            }
            .flag-notifcation & {
                display: block;
            }
            .button-submit {
                @include themeColor('background-color', 'c3');
            }
        }
        .entry-threads {
            padding-top: $gutter-h-s;
            min-height: calc(
                100vh - #{$header-h} - #{$gutter-h + 3 * $font-size * $line-height}
            );
            .flag-iphone-layout & {
                padding-bottom: $gutter-iphone;
            }
            .thread-swipe {
                padding-bottom: $gutter-h-s;
                .switch-moderate-select & {
                    padding-bottom: 0;
                }
                &:last-child {
                    padding-bottom: 0;
                }
                &.switch-to-moderate {
                    padding-bottom: 0;
                    .switch-moderate-select & {
                        padding-bottom: $gutter-h-s;
                    }
                }
                &.switch-swipe-comments-on {
                    padding-bottom: 0;
                }
            }
            .entry-thread {
                position: relative;

                // General in Thread
                .content-buttons {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    justify-content: flex-start;
                    position: absolute;
                    z-index: 50;
                    top: 0;
                    left: 50%;
                    width: 50%;
                    // height: 100%;
                    .button {
                        position: relative;
                        flex: 1 1 auto;
                        width: 100%;
                        height: $button-h;
                        margin-left: $gutter-v-s;
                        @include themeColor('background-color', 'no');
                        font-size: $font-size-s;
                        border-radius: $button-radius;
                        cursor: pointer;
                        @include remove-highlight-tap;
                        &.button-delete {
                            display: none;
                            @include themeColor('background-color', 'no');
                            .flag-admin & {
                                display: block;
                            }
                        }
                        &.button-favorite {
                            display: none;
                            @include themeColor('background-color', 'pin');
                            .flag-admin & {
                                display: block;
                            }
                        }

                        p {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate3d(-50%, -50%, 0);
                        }
                    }
                }
                .flag-my-comment {
                    .button {
                        &.button-delete {
                            display: block;
                        }
                    }
                }

                &.flag-my-message {
                    .content-buttons {
                        .button {
                            display: block;
                            &.button-favorite {
                                display: none;
                                .flag-admin & {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                .message {
                    padding: $gutter-h $gutter-v-l $gutter-h-l $gutter-v;
                    white-space: pre-wrap;
                    word-wrap: break-word;
                }
                .button-more {
                    display: none;
                    position: absolute;
                    z-index: 100;
                    top: $gutter-h-s;
                    right: 0;
                    width: $picto-tab;
                    height: $picto-h;
                    cursor: pointer;
                    @include remove-highlight-tap;
                    .flag-admin & {
                        display: block;
                    }
                    p {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate3d(-50%, -50%, 0);
                        width: $picto-w;
                        height: $picto-h;
                        cursor: pointer;
                        svg {
                            width: 100%;
                            height: 100%;
                            fill: none;
                            @include themeColor('stroke', 'text');
                            stroke-width: $line-w;
                            overflow: visible;
                        }
                    }
                }
                &.flag-my-message {
                    .message-content {
                        .button-more {
                            display: block;
                        }
                    }
                }
                .voting {
                    position: relative;
                    height: $picto-h;
                    .hand {
                        position: absolute;
                        width: $picto-w;
                        height: $picto-h;
                        @include remove-highlight-tap;

                        &.down {
                            left: 0;
                        }
                        &.up {
                            right: 0;
                        }
                        svg {
                            width: 100%;
                            height: 100%;
                            overflow: visible;
                            @include themeColor('fill', 'c2');

                            cursor: pointer;
                        }
                        &.switch-selected {
                            svg {
                                @include themeColor('fill', 'vote');
                            }
                        }
                    }
                    .count-vote {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate3d(-50%, -50%, 0);
                        .count-average {
                            text-align: center;
                        }
                        .count-total {
                            font-size: $font-size-xs;
                        }
                    }
                }
                .content-footer {
                    display: flex;
                    justify-content: space-between;
                    padding: $gutter-h-l $gutter-v $gutter-h $gutter-v;
                    font-size: $font-size-xs;
                    // .credits {
                    // }
                    .comments-link {
                        @include themeColor('color', 'accent');
                    }
                }

                .thread-message {
                    &.switch-pinned {
                        .message-content {
                            @include themeColor('background-color', 'pin');
                        }
                    }
                    .message-content {
                        position: relative;
                        z-index: 100;
                        @include themeColor('background-color', 'c1');
                        border-radius: $radius;
                        transition: transform $duration $easing;
                    }
                }
                &.switch-options-on {
                    .thread-message {
                        .message-content {
                            transform: translate(-50%);
                        }
                    }
                    &.switch-remove {
                        .message-content {
                            transform: translate3d(
                                calc(-100% - #{$gutter-v}),
                                0,
                                0
                            );
                            transition: transform $duration-delete $easing;
                            @media screen and (min-width: $breakpoint-layout) {
                                transform: translate3d(-150%, 0, 0);
                            }
                        }
                        .button {
                            transform: scale(0);
                            transition: transform $duration-delete $easing;
                        }
                    }
                }

                .thread-comments {
                    display: none;
                    position: relative;
                    left: $picto-tab;
                    width: calc(100% - #{$picto-tab});
                    @include themeColor('background-color', 'c1');
                    transition: max-height $duration $easing;
                    .thread-comment {
                        position: relative;
                        width: 100%;
                        margin-bottom: $gutter-h-xs;
                        &.switch-to-moderate {
                            display: none;
                        }
                        &.switch-options-on {
                            .comment-content {
                                transform: translate(-50%);
                            }
                            &.switch-remove {
                                .comment-content {
                                    transform: translate3d(-150%, 0, 0);
                                    transition: transform $duration-delete
                                        $easing;
                                }
                                .button {
                                    transform: scale(0);
                                    transition: transform $duration-delete
                                        $easing;
                                }
                            }
                        }
                        &.flag-my-comment {
                            .comment-content {
                                .button-more {
                                    display: block;
                                }
                            }
                        }
                        .comment-content {
                            position: relative;
                            z-index: 100;
                            transition: transform $duration $easing;

                            .message {
                                font-size: $font-size-s;
                                @include themeColor('background-color', 'c2');
                                border-radius: $radius $radius 0 0;
                            }
                            .voting {
                                @include themeColor('background-color', 'c2');
                                .hand {
                                    svg {
                                        @include themeColor('fill', 'c1');
                                    }
                                    &.switch-selected {
                                        svg {
                                            @include themeColor('fill', 'vote');
                                        }
                                    }
                                }
                            }
                            .content-footer {
                                padding: $gutter-h-l $gutter-v $gutter-h
                                    $gutter-v;
                                font-size: $font-size-xs;
                                @include themeColor('background-color', 'c2');
                                border-radius: 0 0 $radius $radius;
                            }
                        }
                    }
                    .comment-content + .comment-input {
                        margin-top: -$gutter-h-xs;
                    }
                    .comment-input {
                        position: relative;
                        z-index: 1200;
                        @include vendor-prefix-attribute(position, sticky);
                        bottom: 0;
                        width: 100%;
                        height: $gutter-h + 2 * $font-size * $line-height;
                        margin-top: 0;
                        @include themeColor('background-color', 'c1');
                        .flag-iphone-layout & {
                            bottom: $gutter-iphone;
                        }
                        .flag-no-post:not(.flag-admin) & {
                            .form {
                                display: none;
                            }
                            .notice-temporary-close {
                                display: block;
                            }
                        }
                        .flag-no-post.flag-admin & {
                            .form {
                                textarea::placeholder {
                                    @include themeColor('color', 'no');
                                }
                            }
                        }
                        .close-comment {
                            position: absolute;
                            z-index: 1;
                            top: 0;
                            left: 0;
                            width: $picto-tab;
                            height: 100%;
                            padding: 0 $gutter-v-s;
                            @include themeColor(
                                'background-color',
                                'background'
                            );
                            transform: translate3d(-100%, 0, 0);
                            .button-close-comment {
                                position: absolute;
                                top: 50%;
                                width: $picto-w;
                                height: $picto-h;
                                transform: translate3d(0, -50%, 0);
                                cursor: pointer;
                                svg {
                                    width: 100%;
                                    height: 100%;
                                    fill: none;
                                    @include themeColor('stroke', 'navigation');
                                    stroke-width: $line-w;
                                    overflow: visible;
                                }
                            }
                        }
                        .form {
                            textarea {
                                left: $gutter-v-s;
                                width: calc(
                                    100% - #{$picto-tab} - #{$gutter-v-s}
                                );
                                padding: $gutter-h-xs $gutter-v-s;
                                @include themeColor('background-color', 'c2');
                                @include themeColor('color', 'text');
                            }
                            textarea::placeholder {
                                @include themeColor('color', 'c1');
                            }
                        }
                        .notice-temporary-close {
                            display: none;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            p {
                                position: absolute;
                                overflow: hidden;
                                top: 50%;
                                left: 0;
                                width: 100%;
                                padding: 0 $gutter-v;

                                font-size: $font-size-s;
                                text-align: center;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                transform: translate3d(0%, -50%, 0);
                            }
                        }
                        .input-button {
                            svg {
                                // @include themeColor('stroke', 'navigation');
                                @include themeColor(
                                    'stroke',
                                    'navigation-comment'
                                );
                            }
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: -$picto-tab;
                            width: $picto-tab;
                            height: 100%;
                            @include themeColor(
                                'background-color',
                                'background'
                            );
                        }
                    }
                }
                &.switch-comments-on {
                    padding-bottom: $gutter-h-s;
                    .message-content {
                        border-radius: $radius $radius 0 $radius;
                    }
                    .thread-comments {
                        display: block;
                    }
                }
                .thread-polling {
                    .polling-content {
                        position: relative;
                        z-index: 100;
                        @include themeColor('background-color', 'c1');
                        border-radius: $radius;
                        transition: transform $duration $easing;

                        .polling {
                            padding: 0 $gutter-v-s;
                            .row {
                                display: flex;
                                width: 100%;
                                height: $slider-h;
                                font-size: $font-size-xs;
                                & + .row {
                                    margin-top: $gutter-h-s;
                                }
                                &.switch-selected {
                                    .row-label {
                                        @include themeColor(
                                            'color',
                                            'text-in-accent'
                                        );
                                        @include themeColor(
                                            'background-color',
                                            'accent'
                                        );
                                    }
                                }
                                &.switch-solution {
                                    .row-label {
                                        .flag-admin & {
                                            @include themeColor(
                                                'color',
                                                'text-in-yes'
                                            );
                                            @include themeColor(
                                                'background-color',
                                                'yes'
                                            );
                                        }
                                    }
                                }
                                .row-label {
                                    flex: 0 0 auto;
                                    position: relative;
                                    width: 100%;
                                    height: $slider-h;
                                    border-radius: $slider-h/2;
                                    text-transform: uppercase;
                                    @include themeColor(
                                        'background-color',
                                        'c2'
                                    );
                                    margin-right: $gutter-v-xs;
                                    cursor: pointer;
                                    .flag-admin & {
                                        width: $slider-h;
                                        border-radius: 50%;
                                    }
                                    p {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate3d(-50%, -50%, 0);
                                    }
                                }
                                .row-progress {
                                    display: none;
                                    position: relative;
                                    width: 100%;
                                    height: 100%;
                                    margin-right: $gutter-v-s;
                                    @include themeColor(
                                        'background-color',
                                        'c2'
                                    );
                                    // border-radius: $slider-h/2;                  overflow: hidden;
                                    .flag-admin & {
                                        display: block;
                                    }
                                    .progress {
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        height: 100%;
                                        @include themeColor(
                                            'background-color',
                                            'accent'
                                        );

                                        // border-radius: $slider-h/2;
                                        .row-value {
                                            padding: 0 0 0 $gutter-v-s;
                                            @include themeColor(
                                                'color',
                                                'text'
                                            );
                                            p {
                                                position: absolute;
                                                top: 50%;
                                                left: 0%;
                                                padding: 0 0 0 $gutter-v-s;
                                                @include themeColor(
                                                    'color',
                                                    'c1'
                                                );
                                                @include themeColor(
                                                    'color',
                                                    'text-in-accent'
                                                );
                                                transform: translate3d(
                                                    0%,
                                                    -50%,
                                                    0
                                                );
                                            }
                                        }
                                    }
                                }
                                .row-count {
                                    display: none;
                                    position: relative;
                                    width: $slider-h;
                                    height: $slider-h;
                                    .flag-admin & {
                                        display: block;
                                    }

                                    p {
                                        position: absolute;
                                        top: 50%;
                                        right: 0%;
                                        transform: translate3d(0%, -50%, 0);
                                    }
                                }
                            }
                            .button-submit {
                                display: none;
                                margin-top: $gutter-h-s;
                                .flag-admin & {
                                    display: none;
                                }
                            }
                            .feedbacks {
                                .flag-admin & {
                                    display: none;
                                }
                                .feedback {
                                    display: none;
                                    width: 100%;
                                    margin-top: $gutter-h;
                                    padding: $gutter-h $gutter-v;
                                    font-size: $font-size;
                                    @include themeColor('color', 'text');
                                    text-align: left;
                                    // @include themeColor('background-color', 'yes');
                                }
                            }
                            .entry-switch {
                                display: none;
                                .flag-admin & {
                                    display: block;
                                }
                            }
                        }
                    }
                    &.switch-poll-closed {
                        .polling {
                            .button-submit {
                                display: none;
                            }
                            .feedbacks {
                                .feedback-closed {
                                    display: block;
                                }
                            }
                        }
                    }
                    &.switch-poll-closed.switch-poll-shown,
                    &.switch-poll-shown.switch-poll-voted {
                        .polling {
                            .row {
                                .row-label {
                                    width: $slider-h;
                                    border-radius: 50%;
                                    @include themeColor('color', 'text');
                                }
                                &.switch-selected {
                                    .row-label {
                                        @include themeColor(
                                            'color',
                                            'text-in-accent'
                                        );
                                    }
                                }
                                &.switch-solution.switch-selected {
                                    .row-label {
                                        @include themeColor(
                                            'color',
                                            'text-in-yes'
                                        );
                                        border-width: $line-w * 4;
                                        border-style: solid;
                                        @include themeColor(
                                            'border-color',
                                            'accent'
                                        );
                                    }
                                }
                                &.switch-solution {
                                    .row-label {
                                        @include themeColor(
                                            'color',
                                            'text-in-yes'
                                        );
                                        @include themeColor(
                                            'background-color',
                                            'yes'
                                        );
                                        border-width: $line-w * 4;
                                        border-style: solid;
                                        @include themeColor(
                                            'border-color',
                                            'c1'
                                        );
                                    }
                                }
                                .row-progress,
                                .row-count {
                                    display: block;
                                }
                            }
                            .feedbacks {
                                .feedback-thanks {
                                    display: none;
                                }
                            }
                        }
                    }
                    &.switch-poll-shown.switch-poll-voted {
                        .polling {
                            .feedbacks {
                                .feedback-thanks {
                                    display: block;
                                }
                            }
                        }
                    }
                    &.switch-poll-voted {
                        .button-submit {
                            display: none;
                        }
                        .polling {
                            .feedbacks {
                                .feedback-thanks {
                                    display: block;
                                }
                            }
                        }
                    }
                    &.switch-poll-closed.switch-poll-voted {
                        .polling {
                            .feedbacks {
                                .feedback-thanks {
                                    display: none;
                                }
                            }
                        }
                    }
                    &.flag-multiple-solution {
                        .polling {
                            .button-submit {
                                display: block;
                            }
                        }
                    }
                }
                &.switch-options-on {
                    .thread-polling {
                        .polling-content {
                            transform: translate(-50%);
                        }
                        &.switch-remove {
                            .polling-content {
                                transform: translate3d(-150%, 0, 0);
                                transition: transform $duration-delete $easing;
                            }
                            .button {
                                transform: scale(0);
                                transition: transform $duration-delete $easing;
                            }
                        }
                    }
                }
                &:not(.switch-to-moderate) {
                    .switch-moderate-select & {
                        display: none;
                    }
                    .button-moderate {
                        display: none;
                    }
                }
                &.switch-to-moderate {
                    display: none;
                    .switch-moderate-select & {
                        display: block;
                    }
                    .message-content {
                        .button-more {
                            display: none;
                        }
                        // @include themeColor('background-color', 'c1');
                        .voting {
                            display: none;
                        }
                        .content-footer {
                            justify-content: center;
                            .comments-link {
                                display: none;
                            }
                        }
                        .button-moderate {
                            position: absolute;
                            bottom: 0;
                            width: $picto-w + $gutter-v;
                            height: $picto-h + $gutter-h;
                            padding: $gutter-h-s $gutter-v-s;
                            border-radius: $radius;
                            cursor: pointer;
                            &.yes {
                                right: 0;
                                @include themeColor('background-color', 'yes');
                            }

                            &.no {
                                left: 0;
                                @include themeColor('background-color', 'no');
                            }
                            svg {
                                width: 100%;
                                height: 100%;
                                fill: none;
                                @include themeColor('stroke', 'c1');
                                stroke-width: $line-w;
                                overflow: visible;
                            }
                        }
                    }
                }
                &.switch-comment-to-moderate {
                    .switch-moderate-select & {
                        display: block;

                        .button-more {
                            display: none;
                        }
                        .voting {
                            display: none;
                        }
                        .content-footer {
                            justify-content: center;

                            .comments-link {
                                display: none;
                            }
                        }

                        .message-content {
                            border-radius: $radius $radius 0 $radius;
                        }

                        .thread-comments {
                            display: block;
                            border-radius: 0 0 $radius $radius;
                            .thread-comment {
                                display: none;
                                &.switch-to-moderate {
                                    display: block;
                                    border-bottom: none;
                                }
                                .content-footer {
                                    .button-moderate {
                                        display: block;
                                        position: absolute;
                                        bottom: 0;
                                        width: $picto-w + $gutter-v;
                                        height: $picto-h + $gutter-h;
                                        padding: $gutter-h-s $gutter-v-s;
                                        border-radius: $radius;
                                        &.yes {
                                            right: 0;
                                            @include themeColor(
                                                'background-color',
                                                'yes'
                                            );
                                        }

                                        &.no {
                                            left: 0;
                                            @include themeColor(
                                                'background-color',
                                                'no'
                                            );
                                        }
                                        svg {
                                            width: 100%;
                                            height: 100%;
                                            fill: none;
                                            @include themeColor('stroke', 'c1');
                                            stroke-width: $line-w;
                                            overflow: visible;
                                        }
                                    }
                                }
                            }
                            .comment-input {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    // Container Input Message
    // --------------------------------
    .container-input-message {
        @include vendor-prefix-attribute(position, sticky);
        z-index: 1000;
        width: 100%;
        height: 100%;
        bottom: 0;
        @include themeColor('background-color', 'background');
        @include themeColor('color', 'c1');
        transition: transform $duration $easing;
        @include themeColorBoxShadow('background');
        .flag-iphone-layout & {
            bottom: $gutter-iphone;
        }

        .switch-moderate-select & {
            display: none;
        }
        .flag-no-post:not(.flag-admin) & {
            .entry-input {
                .form {
                    display: none;
                }
                .notice-temporary-close {
                    display: block;
                }
            }
        }
        .flag-no-post.flag-admin & {
            .entry-input {
                .form {
                    textarea::placeholder {
                        @include themeColor('color', 'no');
                    }
                }
            }
        }
        // &::before {
        //   content: '';
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 50%;
        //   height: 100%;
        //   @include themeColor('background-color', 'background');
        //   transform: translate3d(-100%, 0, 0);
        // }
        .entry-input {
            position: relative;
            top: 0;
            left: 50%;
            width: 100%;
            height: 100%;
            height: $gutter-h + 2 * $font-size * $line-height;
            transform: translate3d(-50%, 0, 0);
            transition: transform $duration $easing;
            .form {
                textarea {
                    width: calc(100% - #{$picto-tab});
                    .flag-iphone-layout &,
                    .flag-admin & {
                        top: 50%;
                        left: $picto-tab;
                        width: calc(100% - 2 * #{$picto-tab});
                    }
                }
            }
            .notice-temporary-close {
                display: none;
                position: absolute;
                width: 100%;
                height: 100%;
                p {
                    position: absolute;
                    overflow: hidden;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    padding: 0 $gutter-v;

                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    transform: translate3d(0%, -50%, 0);
                }
            }
        }
        .entry-input-options {
            padding-bottom: $gutter-h-l;
            .numbers-choices {
                .slidecontainer {
                    position: absolute;
                    display: inline-block;
                    top: 50%;
                    right: 0;
                    transform: translate3d(0, -50%, 0);
                    width: $slider-w * 2;
                    height: $slider-h;
                    cursor: pointer;

                    @include remove-highlight-tap;
                    .sliderrange {
                        -webkit-appearance: none; /* Override default CSS styles */
                        appearance: none;

                        width: $slider-w * 2;
                        height: $slider-h;
                        @include themeColor('background-color', 'c3');

                        outline: none;
                        border-radius: $slider-h / 2;
                        padding: 0 $slider-padding;
                        margin: 0;
                    }

                    /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
                    .sliderrange::-webkit-slider-thumb {
                        -webkit-appearance: none; /* Override default look */
                        appearance: none;
                        outline: none;
                        width: $slider-h - $slider-padding * 2;
                        height: $slider-h - $slider-padding * 2;
                        @include themeColor('background-color', 'c1');
                        border-radius: $slider-h / 2;
                        cursor: pointer;
                    }

                    .sliderrange::-moz-range-thumb {
                        outline: none;
                        width: $slider-h - $slider-padding * 2;
                        height: $slider-h - $slider-padding * 2;
                        @include themeColor('background-color', 'c1');
                        border-radius: $slider-h / 2;
                        cursor: pointer;
                    }

                    input[type='range']::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        border: none;
                    }
                }
                label {
                    pointer-events: none;
                    position: absolute;
                    left: 0;
                    width: $slider-h;
                    height: $slider-h;
                    @include themeColor('color', 'text');
                    span {
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        font-size: $font-size-xs;
                        transform: translate3d(-50%, -50%, 0);
                    }
                }
            }
        }

        .flag-admin.switch-options-open & {
            .input-button.plus {
                .open {
                    display: none;
                }
                .close {
                    display: block;
                }
            }
        }
    }
    .iphone-extra-layout {
        display: none;
        position: fixed;
        z-index: 200;
        left: 0;
        bottom: 0;
        width: 100%;
        height: calc(#{$gutter-iphone} + 1px);
        @include themeColor('background-color', 'background');
        .flag-iphone-layout & {
            display: block;
        }
    }
}

#root {
    padding: 0;
    @include layout-extratop('padding-top', '0px');
}
